import React, { useState, useEffect } from "react"
import { Helmet } from "react-helmet"
import { StaticImage } from "gatsby-plugin-image"
import { Container, Row, Col, Button } from "react-bootstrap"
import { graphql } from "gatsby"
import "../components/fragments"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Hero from "../components/hero"
import Facet from "../components/facet"
import ArticleTeaser from "../components/articleTeaser"
import { Breadcrumb } from "gatsby-plugin-breadcrumb"
import { getCrumbsObj } from "../utils/ug-utils"
import BannerAd, { BANNERAD_CHILDREN_NEWS } from "../components/bannerAd"

const ArticleTagsPage = ({ data, pageContext }) => {
  // const { id, name } = pageContext
  const { name } = pageContext
  const crumbsObj = getCrumbsObj(pageContext)
  const articlesData = data.articles.edges
  const newsCategories = data.newsCategories.edges.map((edge) => edge.node)
  // const articleTags = data.articleTags.edges.map((edge) => edge.node)
  const itemsPerPage = 5
  const heroImage = <StaticImage src="../images/priscilla-du-preez-OEdkPaxYMXU-unsplash_0.jpg" alt="Untitled Image" />

  // Add an "All" option to the webinar categories.
  newsCategories.push({ id: "all", name: "All Categories", url: "/news" })

  const [list, setList] = useState([...articlesData.slice(0, itemsPerPage)])
  const [loadMore, setLoadMore] = useState(false)
  const [hasMore, setHasMore] = useState(articlesData.length > itemsPerPage)

  const handleLoadMore = () => {
    setLoadMore(true)
  }

  useEffect(() => {
    if (loadMore && hasMore) {
      const currentLength = list.length
      const isMore = currentLength < articlesData.length
      const nextResults = isMore ? articlesData.slice(currentLength, currentLength + itemsPerPage) : []
      setList([...list, ...nextResults])
      setLoadMore(false)
    }
  }, [loadMore, hasMore]) //eslint-disable-line

  useEffect(() => {
    const isMore = list.length < articlesData.length
    setHasMore(isMore)
  }, [list]) //eslint-disable-line

  return (
    <Layout>
      <Helmet
        bodyAttributes={{
          class: "article-list-page",
          "aria-label": "Article List Page",
        }}
      />
      <Seo title={`News & Stories - #${name}`} keywords={[`gatsby`, `application`, `react`]} />

      {/**** Header and Title ****/}
      <div id="rotator" className="shorter-hero">
        <Hero staticImage={heroImage} />
        <div className="container ft-container">
          <h1 className="fancy-title">News &amp; Stories</h1>
          <h2>#{name}</h2>
        </div>
      </div>

      {/**** Body content ****/}
      <div className="page-container">
        <Container>
          {crumbsObj.crumbs && <Breadcrumb {...crumbsObj} aria-label = "Articles"/>}
          <Row className="row-with-vspace site-content">
            <div className="facets col-12">
              <div className="facets col-12">
                <Facet
                  type="list"
                  data={newsCategories}
                  urlPrefix="/news/category"
                  useHashtag={false}
                  className=""
                  linkClassName="btn btn-outline-info"
                  linkSelectedClassName="btn btn-info"
                  selected="all"
                />
                {/* <Facet type="select" title="Tags" data={articleTags} urlPrefix="/news/tags" /> */}
                {/* <Facet type="select" title="Tags" data={articleTags} urlPrefix="/news/tags" selected={id} />
                <Link className="btn btn-outline-secondary" to="/news">
                  Clear
                </Link> */}
              </div>
            </div>
          </Row>
          <Row className="row-with-vspace site-content">
            <Col md={12} className="content-area">
              {/**** Body content ****/}
              <Container>
                {list.length > 0 ? (
                  <>
                    {list.map(({ node }, index) => {
                      return (
                        <>
                          <ArticleTeaser node={node} />
                        </>
                      )
                    })}
                  </>
                ) : (
                  <p>No articles to display.</p>
                )}
                {articlesData.length > itemsPerPage ? (
                  <div className="load-more-container">
                    {hasMore ? <Button onClick={handleLoadMore}>Show More Articles</Button> : ""}
                  </div>
                ) : null}
              </Container>
            </Col>
          </Row>{" "}
        </Container>
      </div>
    </Layout>
  )
}

export default ArticleTagsPage

export const query = graphql`
  query ($id: String) {
    articles: allNodeArticle(
      limit: 1000
      sort: { fields: created, order: DESC }
      filter: { fields: { tags: { eq: $id } } }
    ) {
      edges {
        node {
          ...Article
        }
      }
    }
    newsCategories: allTaxonomyTermNewsCategory(
      # Ensure there are nodes
      sort: { fields: name, order: ASC }
    ) {
      edges {
        node {
          ...TaxonomyTerm
        }
      }
    }
    articleTags: allTaxonomyTermTags(
      # Ensure there are nodes
      sort: { fields: name }
    ) {
      edges {
        node {
          ...TaxonomyTerm
        }
      }
    }
  }
`
